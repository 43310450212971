<template>
    <div class="w-full h-full">
        <v-form-label>Meta Information</v-form-label>
        <v-table-container>
            <v-table>
                <v-table-head>
                    <v-table-row class="border-t border-default">
                        <v-table-header>
                            <v-heading :size="4">
                                Key
                            </v-heading>
                        </v-table-header>

                        <v-table-header>
                            <v-heading :size="4">
                                Value
                            </v-heading>
                        </v-table-header>
                    </v-table-row>
                </v-table-head>

                <v-table-body>
                    <template v-if="metaInformation">
                        <v-table-row v-for="(meta, metaIndex) in metaInformation" :key="'meta_' + metaIndex">
                            <v-table-data>
                                <v-text>
                                    {{ meta.display_key ? meta.display_key : 'N/A' }}
                                </v-text>
                            </v-table-data>

                            <v-table-data>
                                <v-text>
                                    {{ meta.value ? meta.value : 'N/A' }}
                                </v-text>
                            </v-table-data>
                        </v-table-row>
                    </template>

                    <template v-if="!metaInformation || metaInformation.length === 0">
                        <v-table-row-no-results />
                    </template>
                </v-table-body>
            </v-table>
        </v-table-container>
    </div>
</template>

<script>
import JobService from "@/services/modules/job-service";

export default {
    props: {
        job: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            jobService: JobService,
            metaInformation: null,
        };
    },
    created() {
        this.getMetaInformation();
    },
    methods: {
        getMetaInformation(payload = {}) {
            this.isLoading = true;

            this.jobService.getMetaInformation(this.job.order_line_id, payload)
                .then((response) => {
                    this.metaInformation = response.data.data;
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
    },
};
</script>
