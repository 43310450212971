var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full"},[([1, 2, 3].includes(_vm.job.order_line_status_id))?_c('v-section-toolbar',[_c('div',{staticClass:"sm:col-span-3 lg:col-span-4"},[_c('div',{staticClass:"flex flex-row w-full"},[_c('v-form-select',{staticClass:"w-full",attrs:{"options":_vm.assistants,"default-label":"Select Assistant","disabled":_vm.isLoading || [1, 4, 5].includes(_vm.job.order_line_status_id) || !_vm.$me.hasPermission('order-lines.update')},model:{value:(_vm.assistantForm.user_id),callback:function ($$v) {_vm.$set(_vm.assistantForm, "user_id", $$v)},expression:"assistantForm.user_id"}}),_c('v-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.assistantForm.user_id),expression:"assistantForm.user_id"}],staticClass:"w-auto",attrs:{"color":"secondary"},on:{"click":function($event){_vm.assistantForm.user_id = null}}},[_c('i',{staticClass:"fas fa-times"})])],1)]),_c('v-button',{attrs:{"text-alignment":"center","disabled":_vm.isLoading || _vm.assistantForm.user_id === null || [1, 4, 5].includes(_vm.job.order_line_status_id)},on:{"click":function($event){_vm.addAssistant(_vm.assistantForm) || !_vm.$me.hasPermission('order-lines.update')}}},[_vm._v(" Add Assistant ")])],1):_vm._e(),_c('div',{staticClass:"overflow-x-auto"},[_c('v-table',[_c('v-table-head',[_c('v-table-row',[_vm._l((_vm.assistantColumns),function(ref,key){
var label = ref.label;
var fullWidth = ref.fullWidth; if ( fullWidth === void 0 ) fullWidth = true;
return [_c('v-table-header',{key:key,staticClass:"w-1/3",attrs:{"full-width":fullWidth}},[_c('v-heading',{attrs:{"size":4}},[_vm._v(" "+_vm._s(label)+" ")])],1)]}),_c('v-table-header',{attrs:{"full-width":false}})],2)],1),_c('v-table-body',[_vm._l((_vm.job.users),function(assistant,assistantIndex){return [_c('v-table-row',{key:'assistant_' + assistantIndex},[_vm._l((_vm.assistantColumns),function(ref,key){
var label = ref.label;
var fullWidth = ref.fullWidth; if ( fullWidth === void 0 ) fullWidth = true;
var formatter = ref.formatter;
return [_c('v-table-data',{key:'assistant_' + assistantIndex + key,staticClass:"w-1/3",attrs:{"full-width":fullWidth}},[(typeof formatter == 'function')?[_c('v-text',[_vm._v(" "+_vm._s(formatter(assistant))+" ")])]:[_c('v-text',[_vm._v(" "+_vm._s(assistant[key])+" ")])]],2)]}),_c('v-table-data',{attrs:{"full-width":false}},[_c('v-button',{attrs:{"color":"secondary","disabled":assistant.pivot.order_line_user_relationship_type_id != 1 || [4, 5].includes(_vm.job.order_line_status_id) || !_vm.$me.hasPermission('order-lines.update')},on:{"click":function($event){return _vm.removeAssistant(assistant)}}},[_c('i',{staticClass:"fas fa-trash"})])],1)],2)]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }