<template>
    <div class="w-full h-full">
        <v-section-toolbar v-if="[1, 2, 3].includes(job.order_line_status_id)">
            <div class="sm:col-span-3 lg:col-span-4">
                <div class="flex flex-row w-full">
                    <v-form-select v-model="assistantForm.user_id" :options="assistants" default-label="Select Assistant" :disabled="isLoading || [1, 4, 5].includes(job.order_line_status_id) || !$me.hasPermission('order-lines.update')" class="w-full" />
                    <v-button v-show="assistantForm.user_id" color="secondary" class="w-auto" @click="assistantForm.user_id = null">
                        <i class="fas fa-times" />
                    </v-button>
                </div>
            </div>

            <v-button text-alignment="center" :disabled="isLoading || assistantForm.user_id === null || [1, 4, 5].includes(job.order_line_status_id)" @click="addAssistant(assistantForm) || !$me.hasPermission('order-lines.update')">
                Add Assistant
            </v-button>
        </v-section-toolbar>

        <div class="overflow-x-auto">
            <v-table>
                <v-table-head>
                    <v-table-row>
                        <template v-for="({ label, fullWidth = true }, key) in assistantColumns">
                            <v-table-header :key="key" :full-width="fullWidth" class="w-1/3">
                                <v-heading :size="4">
                                    {{ label }}
                                </v-heading>
                            </v-table-header>
                        </template>

                        <v-table-header :full-width="false" />
                    </v-table-row>
                </v-table-head>

                <v-table-body>
                    <template v-for="(assistant, assistantIndex) in job.users">
                        <v-table-row :key="'assistant_' + assistantIndex">
                            <template v-for="({ label, fullWidth = true, formatter }, key) in assistantColumns">
                                <v-table-data :key="'assistant_' + assistantIndex + key" :full-width="fullWidth" class="w-1/3">
                                    <template v-if="typeof formatter == 'function'">
                                        <v-text>
                                            {{ formatter(assistant) }}
                                        </v-text>
                                    </template>

                                    <template v-else>
                                        <v-text>
                                            {{ assistant[key] }}
                                        </v-text>
                                    </template>
                                </v-table-data>
                            </template>

                            <v-table-data :full-width="false">
                                <v-button color="secondary" :disabled="assistant.pivot.order_line_user_relationship_type_id != 1 || [4, 5].includes(job.order_line_status_id) || !$me.hasPermission('order-lines.update')" @click="removeAssistant(assistant)">
                                    <i class="fas fa-trash" />
                                </v-button>
                            </v-table-data>
                        </v-table-row>
                    </template>
                </v-table-body>
            </v-table>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        job: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            assistantColumns: {
                name: {
                    label: "Name",
                    fullWidth: false,
                },
                user_type: {
                    label: "Type",
                    formatter: (row) => row.user_type.name,
                    fullWidth: false,
                },
                relationship: {
                    label: "Relationship",
                    formatter: (row) => row.relationship_type.name,
                    fullWidth: false,
                },
            },
            users: [
                {
                    user_id: 1,
                    name: "John Doe",
                    user_type: {
                        name: "Systems Administrator",
                    },
                },
            ],
            assistantForm: {
                user_id: null,
            },
            assistants: [],
        };
    },
    created() {
        this.fetchAllUsers();
    },
    methods: {
        fetchAllUsers() {
            this.isLoading = true;
            const payload = {
                quick: true,
                is_approved: true,
                paginated: false,
                not_user_types: [1, 3],
            };

            this.$store.dispatch("userModule/fetchAllItems", { ...payload })
                .then((response) => {
                    this.users = response.data;
                    this.assistants = this.selectOptions(this.users);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
        addAssistant(payload) {
            this.toggleLoading();
            this.$store.dispatch("jobModule/addAssistant", { id: this.job.order_line_id, payload })
                .then(({ data }) => {
                    this.$emit("job-updated", data.data);
                })
                .finally(() => {
                    this.assistantForm.user_id = null;
                    this.toggleLoading();
                });
        },
        removeAssistant(user) {
            const payload = {
                user_id: user.user_id,
            };
            this.toggleLoading();
            this.$store.dispatch("jobModule/removeAssistant", { id: this.job.order_line_id, payload })
                .then(({ data }) => {
                    this.$emit("job-updated", data.data);
                })
                .finally(() => {
                    this.assistantForm.user_id = null;
                    this.toggleLoading();
                });
        },
        selectOptions(data) {
            if (data) {
                return data.map((item) => ({ label: item.name, value: item.user_id }));
            }
            return null;
        },
    },
};
</script>
