<template>
    <v-section>
        <order-details :order="job.order" />

        <v-section-group v-if="user && $me.hasPermission('order-lines.update')">
            <v-section-heading>
                Job Actions
            </v-section-heading>

            <template v-if="job.taken_by && [1, 2, 3].includes(job.order_line_status_id)">
                <template v-if="job.taken_by.user_id === user.user_id">
                    <v-section-toolbar>
                        <v-button color="primary" :disabled="isLoading" @click.stop="updateJob({job, take_order_line: false })">
                            Un-Take Job
                        </v-button>
                        <template v-if="job.order_line_status_id == 2">
                            <v-button color="secondary" :disabled="isLoading" @click.stop="updateJob({job, start_order_line: true })">
                                Start Job
                            </v-button>
                        </template>
                        <template v-if="job.order_line_status_id == 3">
                            <v-button color="secondary" :disabled="isLoading" @click.stop="updateJob({job, complete_order_line: true })">
                                Complete Job
                            </v-button>
                        </template>
                    </v-section-toolbar>
                </template>
            </template>

            <template v-else-if="job.taken_by === null && $me.hasPermission('order-lines.update')">
                <v-section-toolbar>
                    <v-button color="primary" :disabled="isLoading" @click.stop="updateJob({job, take_order_line: true })">
                        Take Job
                    </v-button>
                </v-section-toolbar>
            </template>
        </v-section-group>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <v-form-group :permissions="['order-lines.read']">
                <v-form-label>Job ID</v-form-label>
                <v-form-input class="md:border-r border-default" :value="job.order_line_id" type="text" :readonly="true" :disabled="isLoading" />
            </v-form-group>

            <v-form-group :permissions="['order-lines.read']">
                <v-form-label>Order ID</v-form-label>
                <v-form-input class="lg:border-r border-default" :value="job.order_id" type="text" :readonly="true" :disabled="isLoading" />
            </v-form-group>

            <v-form-group :permissions="['order-lines.read']">
                <v-form-label>Status</v-form-label>
                <v-form-input class="md:border-r border-default" :value="job.order_line_status.name" type="text" :readonly="true" :disabled="isLoading" />
            </v-form-group>

            <v-form-group :permissions="['order-lines.read']">
                <v-form-label>Price</v-form-label>
                <v-form-input :value="'$' + job.price" type="text" :readonly="true" :disabled="isLoading" />
            </v-form-group>

            <v-form-group class="col-span-full" :permissions="['order-lines.read']">
                <v-form-label>Product</v-form-label>
                <v-form-input :value="job.product.name" type="text" :readonly="true" :disabled="isLoading" />
            </v-form-group>

            <v-form-group class="col-span-full" :permissions="['order-lines.read']">
                <meta-information-table :job="job" />
            </v-form-group>

            <v-form-group class="col-span-full" :permissions="['order-lines.read']">
                <v-form-label>Assistants</v-form-label>
                <assistants-table :job="{ order_line_id: job.order_line_id, users: job.users, order_line_status_id: job.order_line_status_id }" @job-updated="onJobUpdated" />
            </v-form-group>
        </div>
    </v-section>
</template>

<script>
import JobService from "@/services/modules/job-service";
import OrderDetails from "@/components/application/order/order-details.vue";
import AssistantsTable from "./fragment/assistants-table.vue";
import MetaInformationTable from "./fragment/meta-information-table.vue";

export default {
    components: {
        OrderDetails,
        MetaInformationTable,
        AssistantsTable,
    },
    props: {
        job: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            jobService: JobService,
        };
    },
    computed: {
        user() {
            return this.$me.getUser();
        },
    },
    methods: {
        updateJob(data) {
            this.toggleLoading();
            const payload = {
                take_order_line: data.take_order_line,
                start_order_line: data.start_order_line,
                complete_order_line: data.complete_order_line,
            };

            this.jobService.update(data.job.order_line_id, payload)
                .then((response) => {
                    this.$emit("job-updated", response.data);
                })
                .finally(() => {
                    this.toggleLoading();
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        onJobUpdated(data) {
            this.$emit("job-updated", data);
        },
    },
};
</script>
