<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-default gap-default">
        <v-form-group :permissions="['orders.read']">
            <v-form-label>Customer Name</v-form-label>
            <v-link :to="{ name: 'users.show.orders', params: { user: jobOrder.customer_user_id } }">
                {{ jobOrder.customer_name | customerName }}
            </v-link>
        </v-form-group>

        <v-form-group :permissions="['orders.read']">
            <v-form-label>Order ID</v-form-label>
            <v-link :to="{ name: 'orders.show', params: { order: jobOrder.order_id } }">
                {{ jobOrder.order_id }}
            </v-link>
        </v-form-group>

        <v-form-group :permissions="['orders.read']">
            <v-form-label>Source Order ID</v-form-label>
            <v-text>
                {{ jobOrder.source_order_id }}
            </v-text>
        </v-form-group>

        <v-form-group :hidden="excludeKeys.includes('Order Status')">
            <v-form-label>Order Status</v-form-label>
            <v-text>
                {{ jobOrder.order_status_name }}
            </v-text>
        </v-form-group>

        <v-form-group :permissions="['orders.read']">
            <v-form-label>Created At</v-form-label>
            <v-text>
                {{ jobOrder.created_at }}
            </v-text>
        </v-form-group>

        <template v-if="customer && customer.email">
            <v-form-group>
                <v-form-label>Customer Email</v-form-label>
                <v-text>
                    {{ customer.email }}
                </v-text>
            </v-form-group>
        </template>

        <template v-if="customer && customer.phone_number">
            <v-form-group>
                <v-form-label>Customer Phone</v-form-label>
                <v-text>
                    {{ customer.phone_number }}
                </v-text>
            </v-form-group>
        </template>

        <template v-if="((customer && customer.state) || (customer && customer.zip_code))">
            <v-form-group>
                <v-form-label>Customer {{ customer.state ? 'State' : '' }}{{ customer.zip_code ? (customer.state ? '/Zip' : 'Zip') : '' }}</v-form-label>
                <v-text>
                    {{ customer.state }} {{ customer.zip_code }}
                </v-text>
            </v-form-group>
        </template>

        <v-form-group :permissions="['orders.read']" class="col-span-full">
            <v-form-label>Order Note</v-form-label>
            <v-text>
                {{ jobOrder.latest_note ? jobOrder.latest_note.note : 'No notes available.' }}
            </v-text>
        </v-form-group>
    </div>
</template>

<script>
import JobService from "@/services/modules/job-service";
import _ from "lodash";

export default {
    props: {
        order: {
            type: Object,
            required: false,
            default: () => {},
        },
        orderLine: {
            type: Object,
            required: false,
            default: () => {},
        },
        excludeKeys: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            customer: null,
            jobOrder: {
                customer_user_id: 0,
                order_id: 0,
            },
        };
    },
    watch: {
        orderLine: {
            handler(value) {
                if (value && value.order_line_id) {
                    this.getCustomer(value.order_line_id, {});
                    this.getOrder(value.order_line_id, {});
                }
            },
            deep: true,
            immediate: true,
        },
        order: {
            handler(value) {
                if (value && value.order_id) {
                    this.jobOrder = value;

                    if (value.customer) {
                        this.customer = value.customer;
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        getOrder(orderLineId, payload) {
            JobService.getOrder(orderLineId, payload)
                .then((response) => {
                    this.jobOrder = response.data.data;
                });
        },
        getCustomer(orderLineId, payload) {
            JobService.getCustomer(orderLineId, payload)
                .then((response) => {
                    this.customer = _.isEmpty(response.data) ? null : response.data;
                });
        },
    },
};
</script>
